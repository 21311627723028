<template>
  <div class="col-6 mb-4">
    <div class="card position-relative h-100">
      <div class="card-body">
        <div class="d-flex mb-3">
          <div class="">
            <strong class="me-3">{{ title }}</strong>
            <span v-html="info.count"></span>
          </div>
          <div class="ms-auto btn-group">
            <button
              class="btn btn-sm"
              :class="{
                'btn-light': view === 'line',
              }"
              @click="view = 'line'">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z"/>
              </svg>
            </button>
            <button
              class="btn btn-sm"
              :class="{
                'btn-light': view === 'bar',
              }"
              @click="view = 'bar'">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-line" viewBox="0 0 16 16">
                <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z"/>
              </svg>
            </button>
            <button
              class="btn btn-sm"
              :class="{
                'btn-light': view === 'bar',
              }"
              @click="view = 'table'">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-table" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
              </svg>
            </button>
          </div>
        </div>
        <div v-if="view === 'line'" class="position-relative">
          <LineChart
            :chartData="datasets"
            :labelFormat="labelFormat"
            @clickPoint="clickPoint"
            :width="100"
            :height="300"/>
          <button @click="download" class="btn btn-sm btn-light position-absolute bottom-0 start-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
            </svg>
          </button>
        </div>
        <div v-if="view === 'bar'" class="position-relative">
          <Bar :chartData="datasets" :labelFormat="labelFormat" :width="200" :height="100"/>
          <button @click="download" class="btn btn-sm btn-link position-absolute top-0 start-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
            </svg>
          </button>
        </div>
        <div v-if="view === 'table'" class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Дата</th>
                <th v-for="(label, i) in datasets.labels" :key="i">
                  {{ moment(label * 1000).format(labelFormat) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dataset, i) in datasets.datasets" :key="i">
                <th>{{ dataset.label }}</th>
                <td v-for="(val, i) in dataset.data" :key="i">{{ val }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bar from './graph/Bar'
import LineChart from './graph/LineChart.ts'
import moment from 'moment'
moment.locale('ru')
export default {
  components: {
    Bar,
    LineChart
  },
  data () {
    return {
      view: 'line'
    }
  },
  props: {
    datasets: Array,
    info: Object,
    title: String,
    labelFormat: String,
    period: String,
  },
  created: function () {
    this.moment = moment
  },
  methods: {
    clickPoint (i) {
      // props настроен только для активных участников, поэтому такое условие
      const start = this.datasets.labels[i]
      const end = this.datasets.labels[i + 1]
      if (this.period && start && end) {
        this.$emit('clickPoint', {
          start,
          end,
        })
      }
    },
    download (e) {
      const canvas = e.target.parentElement.parentElement.getElementsByTagName('canvas')[0]
      let link = document.createElement('a')
      link.download = 'image'
      link.href = canvas.toDataURL('image/png')
      link.click()
    },
  },
}
</script>


<style lang="scss" scoped>
.card{
  .btn.position-absolute{
    visibility: hidden;
  }
  &:hover{
    .btn.position-absolute{
      visibility: visible;
    }
  }
}
</style>
