<template>
  <div class="">
    <div class="d-flex">

      <div class="btn-group mb-4">
        <button
          v-for="p in periods"
          :key="p.alias"
          @click="setPeriod(p)"
          class="btn"
          :class="{
            'btn-light': period != p.alias,
            'btn-primary': period === p.alias,
          }">
          {{ p.name }}
        </button>
        <div v-if="period === 'custom'" class="d-flex px-4">
          <div class="me-2">
            <label for="inputDateFrom" class="visually-hidden">Дата от</label>
            <input
              v-model="dateFrom"
              @change="setCustomPeriod"
              type="date"
              class="form-control"
              id="inputDateFrom"
              placeholder="Дата от">
          </div>
          <div class="">
            <label for="inputDateTo" class="visually-hidden">Дата до</label>
            <input
              v-model="dateTo"
              @change="setCustomPeriod"
              type="date"
              class="form-control"
              id="inputDateTo"
              placeholder="Дата до">
          </div>

        </div>
      </div>

      <div class="ms-auto btn-group mb-4">
        <input v-model="sentiments" value="Позитивная" type="checkbox" class="btn-check" id="btn-check-outlined">
        <label class="btn btn-light" for="btn-check-outlined">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-smile" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
          </svg>
        </label>

        <input v-model="sentiments" value="Нейтральная" type="checkbox" class="btn-check" id="btn-check-2-outlined">
        <label class="btn btn-light" for="btn-check-2-outlined">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-neutral" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M4 10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5zm3-4C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5zm4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5S9.448 8 10 8s1-.672 1-1.5z"/>
          </svg>
        </label>

        <input v-model="sentiments" value="Негативная" type="checkbox" class="btn-check" id="btn-check-3-outlined">
        <label class="btn btn-light" for="btn-check-3-outlined">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-frown" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
          </svg>
        </label>
      </div>

    </div>

    <div class="row align-items-stretch" v-if="data">

      <Card
        v-if="metrics.includes('messages')"
        :datasets="data.messages.datasets"
        :labelFormat="labelFormat"
        :period="period"
        @clickPoint="goToMessage"
        :info="data.messages.info"
        :title="'Сообщения'"/>

      <Card
        v-if="metrics.includes('words')"
        :datasets="data.words.datasets"
        :labelFormat="labelFormat"
        :info="data.words.info"
        :title="'Слова'"/>

      <Card
        v-if="metrics.includes('transactions')"
        :datasets="data.transactions.datasets"
        :labelFormat="labelFormat"
        :period="period"
        @clickPoint="goToMessage"
        :info="data.transactions.info"
        :title="'Трансакций'"/>

      <Card
        v-if="metrics.includes('transactions_words')"
        :datasets="data.transactions_words.datasets"
        :labelFormat="labelFormat"
        :info="data.transactions_words.info"
        :title="'Слов в трансакциях'"/>

      <Card
        v-if="metrics.includes('messages_words')"
        :datasets="data.messages_words.datasets"
        :labelFormat="labelFormat"
        :info="data.messages_words.info"
        :title="'Слов в сообщениях'"/>

      <Card
        v-if="metrics.includes('sentiments')"
        :datasets="getSentimentsDataset"
        :labelFormat="labelFormat"
        :period="period"
        @clickPoint="goToMessage"
        :info="data.sentiments.info"
        :title="'Тональность'"/>

      <Card
        v-if="metrics.includes('labels_sentiments')"
        :datasets="getLabelsSentimentsDataset"
        :labelFormat="labelFormat"
        :period="period"
        @clickPoint="goToMessage"
        :info="data.labels_sentiments.info"
        :title="'Тональность (лейблы)'"/>

      <div v-if="metrics.includes('labels')" class="col-3 mb-4">
        <div class="card card-body">
          <strong>Упоминание лейблов</strong>
          <div
            v-for="(l, i) in data.labels.info.labels"
            :key="i"
            @click="getLabelSentiments(l)"
            class="lead text-nowrap hover-pointer"
            :class="{
              'text-primary': label.id === l.id
            }">
            {{ l.name }}
            -
            {{ l.count }}
          </div>
        </div>
      </div>

      <Card
        v-if="metrics.includes('active_members')"
        :datasets="data.active_members.datasets"
        :labelFormat="labelFormat"
        :period="period"
        @clickPoint="clickPoint"
        :info="data.active_members.info"
        :title="'Активные участники'"/>

      <Card
        v-if="metrics.includes('members')"
        :datasets="data.members.datasets"
        :labelFormat="labelFormat"
        :info="data.members.info"
        :title="'Участники'"/>

      <div v-if="metrics.includes('words_freq')" class="col-3 mb-4">
        <div class="card">
          <div class="card-body">
            <strong>Наиболее упоминаемые слова</strong>
            <div class="lead d-flex flex-wrap">
              <div
                v-for="(word, i) in data.words_freq.info.counter"
                :key="i"
                class="me-2 text-nowrap">
                <small class="me-1">{{ word[0] }}</small>
                <span class="text-success">{{ word[1] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="metrics.includes('popuar_messages')" class="col-12 mb-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex mb-3">
              <strong class="">Популярные сообщения</strong>
              <div class="ms-auto btn-group">
                <button
                  class="btn btn-sm"
                  :class="{
                    'btn-secondary': popular === 5,
                    'btn-link': popular != 5,
                  }"
                  @click="popular = 5">
                  5
                </button>
                <button
                  class="btn btn-sm"
                  :class="{
                    'btn-secondary': popular === 10,
                    'btn-link': popular != 10,
                  }"
                  @click="popular = 10">
                  10
                </button>
                <button
                  class="btn btn-sm"
                  :class="{
                    'btn-secondary': popular === 30,
                    'btn-link': popular != 30,
                  }"
                  @click="popular = 30">
                  30
                </button>
              </div>
            </div>
            <MessagesTable
              :messages="data.popuar_messages.slice(0, popular)"
              :linkToChat="true"/>
          </div>
        </div>
      </div>

      <button
        v-if="$route.name === 'Chat'"
        class="btn btn-dark btn-sm ms-auto"
        @click="$socket.emit('get_chat_info', {id: $store.state.chat.id, period, metrics: ['members']})">
        Обновить участников
      </button>

    </div>

    <div
      class="modal fade"
      id="activeMembersModal"
      ref="activeMembersModal"
      tabindex="-1"
      aria-labelledby="titleActiveMembersModal"
      aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="titleActiveMembersModal">Активные участники</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <AccountsTable :accounts="activeMembers"/>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
moment.locale('ru')
// Изучать показатели чатов и тегов и смотреть их
// историю (за час, с начала суток с разбивкой по часам, за последние
// 24 часа с разбивкой по часам, за неделю с разбивкой по дням недели,
// за месяц с разбивкой по дням, за 6 месяцев с разбивкой по неделям,
// за 1 год с разбивкой по месяцам, за выбранный период)
import Card from './Card'
import MessagesTable from './chat/MessagesTable'
import AccountsTable from './AccountsTable'
import { Modal } from 'bootstrap'
export default {
  components: {
    Card,
    MessagesTable,
    AccountsTable,
  },
  created: function () {
    this.moment = moment
  },
  data () {
    return {
      period: '24h',
      labelFormat: 'HH:mm',
      periods: [
        {name: '24 часа', alias: '24h', labelFormat: 'HH:mm'},
        {name: 'День', alias: 'day', labelFormat: 'HH:mm'},
        {name: 'Неделя', alias: 'week', labelFormat: 'DD.MM'},
        {name: 'Месяц', alias: 'month', labelFormat: 'DD.MM'},
        {name: '6 месяцев', alias: '6m', labelFormat: 'DD.MM'},
        {name: 'Год', alias: 'year', labelFormat: 'MMM'},
        {name: 'Настроить', alias: 'custom', labelFormat: 'MMM'},
      ],
      sentiments: ['Позитивная', 'Нейтральная', 'Негативная'],
      loading: true,
      data: null,
      popular: 5,
      metrics: [],
      dateFrom: null,
      dateTo: null,
      activeMembersModal: null,
      activeMembers: [],
      label: {}
    }
  },
  sockets: {
    set_chat_stat: function (data) {
      this.loading = false
      // чтоб получить тональности отдельного лейбла // TODO: рефакторить
      if (data.metrics && data.metrics.length == 2 && data.metrics[0] === 'labels_sentiments') {
        this.data.labels_sentiments = data.labels_sentiments
      } else {
        if (data.metrics) {
          this.metrics = data.metrics
        }
        this.data = data
      }
      this.$store.commit('mergeStore', {loading: false})
    },
  },
  computed: {
    getSentimentsDataset () {
      return {
        labels: this.data.sentiments.datasets.labels,
        datasets: this.data.sentiments.datasets.datasets.filter(d => this.sentiments.includes(d.label))
      }
    },
    getLabelsSentimentsDataset () {
      return {
        labels: this.data.labels_sentiments.datasets.labels,
        datasets: this.data.labels_sentiments.datasets.datasets.filter(d => this.sentiments.includes(d.label))
      }
    },
  },
  methods: {
    async getLabelSentiments (label) {
      this.label = label
      console.log('getLabelSentiments', label);
      // TODO: данное решение надо рефакторить
      this.$socket.emit('get_chat_stat', {
        id: this.$store.state.chat.id,
        period: this.period,
        sentiments: this.sentiments,
        dateFrom: moment(this.dateFrom).unix(),
        dateTo: moment(this.dateTo).unix(),
        label_id: label.id
      })
    },
    async clickPoint (params) {
      try {
        console.log('Statistic clickPoint', params)
        this.activeMembers = []
        this.activeMembersModal.show()
        const url = this.$route.name === 'Tag' ? `/api/tags/${this.$store.state.tag.id}` : `/api/chats/${this.$store.state.chat.id}`
        const response = await fetch(`${url}/active_members?${new URLSearchParams(params)}`)
        const data = await response.json()
        this.activeMembers = data.accounts
        console.log('this.activeMembers', this.activeMembers);
      } catch (e) {
        this.$toast.error(`${e.type}: ${e.message}`)
      }
    },
    async goToMessage (params) {
      try {
        if (this.$route.name === 'Chat') {
          console.log('Statistic goToMessage', params.start)
          const url = `/api/chats/${this.$store.state.chat.id}/get_message?start=${params.start}&end=${params.end}`
          const response = await fetch(url)
          const data = await response.json()
          console.log('Statistic goToMessage', data)
          // this.$store.commit('mergeStore', {
          //   chatTab: 'chat',
          //   messages: data.messages,
          //   messagesSearch: {
          //     ...this.$store.state.messagesSearch,
          //     dateFrom: moment(params.start * 1000).format().substr(0, 16),
          //     dateTo: moment(params.end * 1000).format().substr(0, 16)
          //   }
          // })
          if (data.message.telegram_id) {

            const messagesUrl = `${window.location.origin}/chats/${this.$store.state.chat.id}?tab=chat&dateFrom=${params.start}&dateTo=${params.end}`
            window.open(messagesUrl, '_blank')

            const url = 'https://t.me/c/' + this.$store.state.chat.telegram_id + '/' + data.message.telegram_id
            console.log('url', url)
            window.open(url, '_blank')
          } else {
            this.$toast.error(`Сообщения в БД не найдены`)
          }
        }
      } catch (e) {
        this.$toast.error(`${e.type}: ${e.message}`)
      }
    },
    setCustomPeriod () {
      if (this.dateFrom && this.dateTo) {
        const days = (moment(this.dateTo) - moment(this.dateFrom)) / (3600 * 24 * 1000)
        if (days < 4) {
          this.labelFormat = 'HH:mm'
        } else if (days < 180) {
          this.labelFormat = 'DD.MM'
        } else {
          this.labelFormat = 'MMM'
        }
        this.get_stat()
      }
    },
    setPeriod (period) {
      this.period = period.alias
      this.labelFormat = period.labelFormat
      if (this.period === '24h') {
        this.dateTo = moment().add(1, 'hour').minutes(0).seconds(0).milliseconds(0)
        this.dateFrom = moment(this.dateTo).subtract(24, 'hour')
      } else if (this.period === 'day') {
        this.dateTo = moment().add(1, 'hour').minutes(0).seconds(0).milliseconds(0)
        this.dateFrom = moment().startOf('day')
      } else if (this.period === 'week') {
        this.dateTo = moment().add(1, 'day').startOf('day')
        this.dateFrom = moment(this.dateTo).subtract(7, 'day')
      } else if (this.period === 'month') {
        this.dateTo = moment().add(1, 'day').hours(0).minutes(0).seconds(0).milliseconds(0)
        this.dateFrom = moment(this.dateTo).subtract(1, 'month')
      } else if (this.period === '6m') {
        this.dateTo = moment().add(1, 'week').startOf('week')
        this.dateFrom = moment(this.dateTo).subtract(6, 'month')
      } else if (this.period === 'year') {
        this.dateTo = moment().add(1, 'month').startOf('month')
        this.dateFrom = moment(this.dateTo).subtract(1, 'year')
      }
      this.get_stat()
    },
    download (e) {
      const canvas = e.target.parentElement.parentElement.getElementsByTagName('canvas')[0]
      let link = document.createElement('a')
      link.download = 'image'
      link.href = canvas.toDataURL('image/png')
      link.click()
    },
    get_stat () {
      this.$store.commit('mergeStore', {loading: true})
      if (this.$route.name === 'Chat') {
        this.metrics = [
          'metrics',
          'active_members',
          'labels',
          'labels_sentiments',
          'members',
          'messages',
          'messages_words',
          'sentiments',
          'words',
          'words_freq',
          'popuar_messages',
          'transactions',
          'transactions_words',
        ]
        this.$socket.emit('get_chat_stat', {
          id: this.$store.state.chat.id,
          period: this.period,
          sentiments: this.sentiments,
          dateFrom: moment(this.dateFrom).unix(),
          dateTo: moment(this.dateTo).unix(),
        })
      } else if (this.$route.name === 'Tag') {
        this.$socket.emit('get_tag_stat', {
          id: this.$store.state.tag.id,
          period: this.period,
          sentiments: this.sentiments,
          dateFrom: moment(this.dateFrom).unix(),
          dateTo: moment(this.dateTo).unix(),
        })
      }
    },
  },
  mounted () {
    this.data = null
    this.$store.commit('mergeStore', {loading: true})
    this.dateTo = moment().add(1, 'hour').minutes(0).seconds(0).milliseconds(0)
    this.dateFrom = moment(this.dateTo).subtract(24, 'hour')
    this.get_stat()
    this.activeMembersModal = new Modal(this.$refs.activeMembersModal)
  }
}
</script>
