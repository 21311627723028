<template>
  <div class="table-responsive">
    <table class="table table-striped">
      <tbody>
        <tr
          v-for="message in messages"
          :key="message.id">
          <td>
            <a
              :href="'https://t.me/c/' + $store.state.chat.telegram_id + '/' + message.telegram_id"
              target="_blank"
              :class="getLinkClass(message)">
              {{ message.telegram_id }}
            </a>
            <button v-if="linkToChat" @click="goToMessage(message)" class="btn btn-link">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-square-text" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
              </svg>
            </button>
            <br>
            <div>
              <input class="form-check-input" type="checkbox" :value="message.id" v-model="$store.state.chatSelectedMessages">
            </div>
          </td>
          <td>
            <div class="d-flex">
              <div class="">
                <strong class="me-1" v-if="message.account">{{ message.account.first_name }} {{ message.account.last_name }} {{ message.account.username }} {{ message.account.phone }}</strong>
                <strong class="me-1" v-else>Аккаунт</strong>
                <small class="text-secondary me-2">
                  {{ moment(message.date).format('lll') }}
                </small>
                <button @click="copy($event, 'https://t.me/' + $store.state.chat.telegram_name + '/' + message.telegram_id)" class="btn btn-sm btn-link py-0 mb-0" title="Скопировать ссылку">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-plus" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"/>
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                  </svg>
                </button>
                <button @click="copy($event, message.message)" class="btn btn-sm btn-link py-0 mb-0" title="Скопировать текст">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                  </svg>
                </button>
                <br>
              </div>

              <div
                v-if="message.sent || editSentMessageId === message.id"
                class="ms-auto btn-group">
                <input
                  v-model="message.sent"
                  @change="updateMessageSent(message)"
                  value="positive"
                  type="radio"
                  class="btn-check"
                  :id="'btn-check-' + message.id + '-pos'">
                <label class="btn btn-light" :for="'btn-check-' + message.id + '-pos'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-smile text-success" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                  </svg>
                </label>
                <input
                  v-model="message.sent"
                  @change="updateMessageSent(message)"
                  value="neutral"
                  type="radio"
                  class="btn-check"
                  :id="'btn-check-' + message.id + '-neu'">
                <label class="btn btn-light" :for="'btn-check-' + message.id + '-neu'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-neutral text-warning" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M4 10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5zm3-4C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5zm4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5S9.448 8 10 8s1-.672 1-1.5z"/>
                  </svg>
                </label>
                <input
                  v-model="message.sent"
                  @change="updateMessageSent(message)"
                  value="negative"
                  type="radio"
                  class="btn-check"
                  :id="'btn-check-' + message.id + '-neg'">
                <label class="btn btn-light" :for="'btn-check-' + message.id + '-neg'">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-frown text-danger" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                  </svg>
                </label>
              </div>

              <div
                class="ms-auto d-flex align-items-center"
                v-else
                @click="editSentMessageId = message.id">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-smile text-success" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                </svg>
                <span class="ps-1 pe-2 text-success">{{ message.positive }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-neutral text-warning" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M4 10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5zm3-4C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5zm4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5S9.448 8 10 8s1-.672 1-1.5z"/>
                </svg>
                <span class="ps-1 pe-2 text-warning">{{ message.neutral }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-frown text-danger" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                </svg>
                <span class="ps-1 pe-2 text-danger">{{ message.negative }}</span>
              </div>
            </div>
            <span v-if="highlightLabels && message.message" v-html="getMessageWithLabels(message.message)"></span>
            <div v-else-if="message.message" v-html="message.message.replaceAll('\n', '<br>')"></div>
            <br>
            <span v-for="(v, k) in message.reactions" class="me-2 badge bg-primary" :key="k">{{ k }} {{ v }}</span>
            <span v-if="message.replies" class="text-secondary">Ответов: {{ message.replies }}</span>
            <div class="d-flex pt-2">
              <div class="" v-for="attachment in message.attachments" :key="attachment.id">
                <img
                  width="150"
                  v-if="attachment.type === 'photo' || attachment.mime_type === 'webp'"
                  :src="'/uploads/' + $store.state.chat.id + '/' + attachment.name"
                  alt="">
                <!-- <video
                  width="150"
                  v-else-if="attachment.type === 'video'"
                  controls
                  :src="'/uploads/' + $store.state.chat.id + '/' + attachment.name">
                </video> -->
                <video-player
                  v-else-if="attachment.type === 'video'"
                  :src="'/uploads/' + $store.state.chat.id + '/' + attachment.name"
                  controls
                  :volume="0.5"
                />
                <!--:loop="true"
                  poster="/your-path/poster.jpg"
                  @mounted="..."
                @ready="..."
                @play="..."
                @pause="..."
                @ended="..."
                @seeking="..." -->

                <div v-else class="">
                  <a :href="'/uploads/' + $store.state.chat.id + '/' + attachment.name" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-arrow-down" viewBox="0 0 16 16">
                      <path d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5z"/>
                      <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
                    </svg>
                    Файл
                  </a>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from 'moment'
moment.locale('ru')
export default {
  props: {
    messages: Object,
    highlightLabels: Boolean,
    linkToChat: {
      type: Boolean,
      default: false
    }
  },
  created: function () {
    this.moment = moment
  },
  data () {
    return {
      editSentMessageId: null
    }
  },
  computed: {
    getRegexp () {
      const words = []
      this.$store.state.chat.labels.map(label => {
        words.push(label.name.toLowerCase())
        label.variants.map(variant => {
          words.push(variant.toLowerCase())
        })
      })
      return words.join('|')
    }
  },
  methods: {
    goToMessage (message) {
      const start = message.date / 1000
      const end = start + 86400
      const link = `${window.location.origin}/chats/${this.$store.state.chat.id}?tab=chat&dateFrom=${start}&dateTo=${end}`
      window.open(link, '_blank')
    },
    updateMessageSent (message) {
      this.$socket.emit('messages', {
        id: message.id,
        sent: message.sent,
      })
    },
    getMessageWithLabels (text) {
      return text.replace(new RegExp(this.getRegexp, "gi"), match => {
        return '<mark>' + match + '</mark>';
      })
    },
    getLinkClass (message) {
      if (message.positive > message.neutral && message.positive > message.negative) {
        return 'text-success'
      } else if (message.neutral > message.positive && message.neutral > message.negative) {
        return 'text-warning'
      } else if (message.negative > message.neutral && message.negative > message.positive) {
        return 'text-danger'
      } else if (message.positive === message.negative && message.positive > message.neutral) {
        return 'text-warning'
      } else if (message.positive === message.neutral && message.positive > message.negative) {
        return 'text-success'
      } else if (message.negative === message.neutral && message.negative > message.positive) {
        return 'text-danger'
      }
      return 'text-warning'
    },
    copy (event, text) {
      event.preventDefault()
      this.$toast.info('Скопировано')
      if (window.clipboardData && window.clipboardData.setData) {
        return window.clipboardData.setData('Text', text)
      }
      else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement('textarea')
        textarea.textContent = text
        textarea.style.position = 'fixed'
        document.body.appendChild(textarea)
        textarea.select()
        try {
          return document.execCommand("copy")
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex)
          return prompt('Copy to clipboard: Ctrl+C, Enter', text)
        } finally {
          document.body.removeChild(textarea);
        }
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
