<template>
  <div
    class="card mb-3 me-3 position-relative"
    @click="toggle_label(label)">
    <div class="card-body">
      <h5 class="card-title">{{ label.name }}</h5>
    </div>
    <div class="position-absolute top-0 end-0 badge bg-pink fs-12">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-tags" viewBox="0 0 16 16">
        <path d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z"/>
        <path d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: Object
  },

  methods: {
    toggle_label (label) {
      if (this.$route.name === 'Chat') {
        if (this.$store.state.chat.label_ids.includes(label.id)) {
          this.$toast.error('Отключение лейбла')
        } else {
          this.$toast.success('Включение лейбла')
        }
      } else if (this.$route.name === 'Tag') {
        if (this.$store.state.tag.label_ids.includes(label.id)) {
          this.$toast.error('Отключение лейбла')
        } else {
          this.$toast.success('Включение лейбла')
        }
      }
      this.$socket.emit('toggle_label', {
        label_id: label.id,
        model_name: this.$route.name,
        model_id: this.$route.params.id,
      })
    }
    // this.$store.state.labels.filter(c => !)
  }
}
</script>

<style lang="scss" scoped>
.card:hover{
  cursor: pointer;
}
</style>
