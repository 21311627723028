import { defineComponent, h } from 'vue'

import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
)

import moment from 'moment'
moment.locale('ru')

export default defineComponent({
  name: 'LineChart',
  components: {
    Line
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    labelFormat: {
      type: String,
      default: 'HH:mm'
    },
  },

  emits: ['clickPoint'],

  setup (props, { emit }) {
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      radius: 3,
      plugins: {
        legend: {
          labels: {
            // This more specific font property overrides the global property
            font: {
              size: 12
            }
          },
          position: 'bottom',
        }
      },
      layout: {
        padding: 0
      },
      elements: {
        line: {
          tension: 0.35
        },
      },
      onClick: function(evt, i) {
        if (i.length) {
          emit('clickPoint', i[0].index);
        }
      }
    }

    return () =>
      h(Line, {
        chartData: {
          datasets: props.chartData.datasets,
          labels: props.chartData.labels.map(l => moment(l * 1000).format(props.labelFormat)),
        },
        chartOptions,
        chartId: props.chartId,
        width: props.width,
        height: props.height,
        cssClasses: props.cssClasses,
        styles: props.styles,
        plugins: props.plugins
      })
  },

  methods: {
    getActiveMembers (i) {
      this.$emit('get_point_click', i);
    }
  }
})
